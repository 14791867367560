import request from '@/common/utils/request'
import Vue from "vue";

export function getResourceList() {
  return request({
    url: Vue.prototype.$adminApiUrl + '/resource/list',
    method: 'post'
  })
}

export function addResource(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/resource/add',
    method: 'post',
    data
  })
}

export function updateResource(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/resource/update',
    method: 'post',
    data
  })
}

export function deleteResource(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/resource/delete',
    method: 'post',
    data
  })
}
