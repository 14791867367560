<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" @click="handleAdd(0, 'ROOT', 1)"
                >新增ROOT资源</el-button
              >
            </el-form-item>
          </el-form>

          <el-form :inline="true" size="medium">
            <!-- <el-form-item label="用户名">
              <el-input v-model="searchForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="search">刷新</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :height="tableHeight"
      row-key="id"
      border
      style="width: 100%"
      :default-expand-all="false"
      :expand-row-keys="expandRowKeys"
      :indent="20"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <!-- <el-table-column
        prop="id"
        label="序号"
        type="index"
        align="center"
        width="80">
      </el-table-column> -->
      <el-table-column prop="title" label="title" align="left" min-width="150">
      </el-table-column>
      <el-table-column
        prop="pagePath"
        label="pagePath"
        align="center"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="componentName"
        label="componentName"
        align="center"
        min-width="130"
      >
      </el-table-column>
      <el-table-column
        prop="dataPath"
        label="dataPath"
        align="center"
        min-width="180"
      >
      </el-table-column>
      <el-table-column prop="type" label="type" align="center" min-width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.type == 1"> menu </template>
          <template v-else-if="scope.row.type == 2"> data </template>
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="icon" align="center" min-width="50">
        <template slot-scope="scope">
          <span class="svg-container" v-if="scope.row.icon">
            <svg-icon :icon-class="scope.row.icon" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="sort" align="center" min-width="50">
      </el-table-column>

      <el-table-column
        prop=""
        fixed="right"
        label="操作"
        align="center"
        min-width="200"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.level <= 2 && scope.row.type == 1">
            <el-button
              size="mini"
              type="success"
              @click="handleAdd(scope.row.id, scope.row.title, scope.row.level)"
              >添加下级</el-button
            >
          </template>
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="editForm.id == 0 ? '新增资源' : '修改资源'"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item
          label="父节点"
          label-width="150px"
          v-if="editForm.parentTitle != ''"
        >
          <el-input
            v-model="editForm.parentTitle"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="title" label-width="150px" prop="title">
          <el-input v-model="editForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="pagePath" label-width="150px" prop="pagePath">
          <el-input v-model="editForm.pagePath" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="componentName"
          label-width="150px"
          prop="componentName"
        >
          <el-input
            v-model="editForm.componentName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="dataPath" label-width="150px" prop="dataPath">
          <el-input v-model="editForm.dataPath" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="type" label-width="150px" prop="role">
          <el-select v-model="editForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="icon" label-width="150px" prop="icon">
          <el-input v-model="editForm.icon" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="sort" label-width="150px" prop="sort">
          <el-input
            v-model="editForm.sort"
            autocomplete="off"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import {
  getResourceList,
  addResource,
  updateResource,
  deleteResource,
} from "@/api/resource";

export default {
  name: "resourceManage",
  data() {
    return {
      tableData: [],
      tableHeight: 200,
      expandRowKeys: [],
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        parentId: 0,
        parentTitle: "",
        title: "",
        pagePath: "",
        componentName: "",
        dataPath: "",
        type: "",
        icon: "",
        sort: "",
      },
      editRules: {
        // nickname: [{ required: true, message:"请输入昵称", trigger: 'blur'}],
        // username: [{ required: true, message:"请输入用户名", trigger: 'blur'}],
        // password: [{ required: true, min:6, message:"密码不能少于6个字符", trigger: 'blur'}],
        // role: [{ required: true, message:"请选择角色", trigger: 'blur'}],
      },
      loadingDiv: null,
      typeList: [],
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 150;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 150;
      });
    };
  },
  methods: {
    handleAdd(parentId, parentTitle, level) {
      // if(this.$refs.editForm){
      //   this.$refs.editForm.clearValidate();
      // }

      this.editForm.id = 0;
      this.editForm.parentId = parentId;
      this.editForm.parentTitle = parentTitle;
      this.editForm.title = "";
      this.editForm.pagePath = "";
      this.editForm.componentName = "";
      this.editForm.dataPath = "/adminapi/";
      this.editForm.icon = "";
      this.editForm.sort = 999;
      this.editFormVisible = true;
      if (level < 2) {
        this.typeList = [
          { id: 1, value: "menu" },
          { id: 2, value: "data" },
        ];
        this.editForm.type = 1;
      } else {
        this.typeList = [{ id: 2, value: "data" }];
        this.editForm.type = 2;
      }
    },
    handleEdit(index, row) {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      let parentTitle = "ROOT";

      outer: for (let i in this.tableData) {
        if (this.tableData[i].id == row.parentId) {
          parentTitle = this.tableData[i].title;
          break;
        }
        for (let j in this.tableData[i].children) {
          if (this.tableData[i].children[j].id == row.parentId) {
            parentTitle = this.tableData[i].children[j].title;
            break outer;
          }
        }
      }

      this.editForm.id = row.id;
      this.editForm.parentId = row.parentId;
      this.editForm.parentTitle = parentTitle;
      this.editForm.title = row.title;
      this.editForm.pagePath = row.pagePath;
      this.editForm.componentName = row.componentName;
      this.editForm.dataPath = row.dataPath;
      this.editForm.type = row.type;
      this.editForm.icon = row.icon;
      this.editForm.sort = row.sort;

      if (row.level <= 2) {
        this.typeList = [
          { id: 1, value: "menu" },
          { id: 2, value: "data" },
        ];
        this.editForm.type = row.type;
      } else {
        this.typeList = [{ id: 2, value: "data" }];
        this.editForm.type = row.type;
      }

      this.editFormVisible = true;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateResource;
          if (this.editForm.id == 0) {
            func = addResource;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm("是否确认删除资源:" + row.title, "删除确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteResource({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getResourceList()
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            this.tableData = res.data;
            let expandRowKeys = [];
            // for(let i in this.tableData){
            //   expandRowKeys.push(''+this.tableData[i].id);
            // }
            // this.expandRowKeys = expandRowKeys;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
</style>
